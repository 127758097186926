<template>
  <div>
    <el-tooltip popper-class="tooltip" effect="light" content="原文信息错误反馈" placement="bottom"
                :visible-arrow="false" :enterable="false">
      <i class="button correction" ref="openCorrectionDialog" @click="openDialog">纠错</i>
    </el-tooltip>

    <MyDialog ref="dialog" height="400px" :isShow.sync="isShow" :position="position" title="我要纠错"
              @handleClose="handleClose">
      <div class="content">
        <el-radio-group v-model="correctionType" @change="radioChange">
          <el-radio v-for="item in correctionList" :key="item.value" :label="item.value">
            {{item.lable}}</el-radio>

        </el-radio-group>
        <div class="textarea_box">
          <el-input :disabled="correctionType !== '其他'" type="textarea"
                    placeholder="您的点滴反馈，是我们前进的动力" resize="none" v-model="feedback" maxlength="100"
                    :rows="4" show-word-limit @focus="hideWarning">
          </el-input>
          <p v-if="isWarning" class="warning_text">请补充描述信息</p>
        </div>

        <div class="foot tac">
          <el-button size="mini" type="primary" @click="submitFeedback">提交</el-button>
          <el-button size="mini" plain @click="closeDialog">取消</el-button>
        </div>
      </div>
    </MyDialog>

  </div>
</template>
<script>
import MyDialog from '@/components/myDialog/newIndex.vue'
import { getDocumentById } from '@/api/article'
import { submitDocFeedback } from '@/api/feedback'
import { mapGetters } from 'vuex'
import { confirm, getCurrentBrowser, getOs } from '@/utils/index.js'

export default {
  components: {
    MyDialog
  },
  props: {
    documentId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isShow: false,
      position: {
        x: '',
        y: ''
      },
      correctionType: '',
      feedback: '',
      isWarning: false,
      correctionList: [
        {
          lable: '原文与标题不符',
          value: '原文与标题不符'
        }, {
          lable: '原文内容不完整',
          value: '原文内容不完整'
        }, {
          lable: '其他',
          value: '其他'
        }
      ]
    }
  },
  methods: {
    submitFeedback () {
      if (!this.correctionType) return this.$message.warning('请选择需要反馈的问题')
      if (this.correctionType === '其他' && !this.feedback) return (this.isWarning = true)
      confirm({
        warning: '是否确认提交?',
        success: async () => {
          const { data } = await getDocumentById({ documentId: this.documentId })
          await submitDocFeedback({
            documentId: this.documentId,
            documentPmid: data.pmid,
            documentTitle: data.docTitle,
            documentDoi: data.docDoi,
            feedbackType: '原文纠错',
            feedbackContent: this.correctionType === '其他' ? `${this.correctionType}: ${this.feedback}` : this.correctionType,
            deviceName: getCurrentBrowser(),
            systemVersion: 'PC',
            terminalType: getOs(),
            appVersion: 'PC'
          })
          this.$message.success('感谢您的反馈，我们会尽快处理')
          this.closeDialog()
        },
        catch: (err) => {
          console.log(err)
          this.$message.error('反馈失败')
        }
      })
    },
    closeDialog () {
      this.$refs.dialog.closeDialog()
    },
    radioChange (val) {
      this.feedback = ''
      this.hideWarning()
    },
    hideWarning () {
      this.isWarning = false
    },
    handleClose () {
      this.correctionType = ''
      this.radioChange()
    },
    openDialog () {
      if (!this.userId) return this.$login()
      const position = this.$refs.openCorrectionDialog.getBoundingClientRect()
      this.position.x = position.left
      this.position.y = position.top
      this.isShow = true
    }
  },
  computed: {
    ...mapGetters(['userId'])
  }
}
</script>
<style lang="less" scoped>
.button {
  display: inline-block;
  width: 26px;
  height: 28px;
  text-align: center;
  line-height: 28px;
  border-radius: 3px;
  font-size: 11px;
  margin-right: 0px;
  color: #000;
  cursor: default;
  background-color: #f9f9fa;
  &:hover {
    background-color: #dddedf;
  }
}
.content {
  width: 100%;
  height: calc(100% - 46px);
  overflow-y: auto;
  padding: 20px 20px 50px;
  /deep/.el-radio-group {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 15px 0;
  }
  .textarea_box {
    margin-top: 5px;
    .warning_text {
      // font-size: 10px;
      color: #f00;
    }
  }
  .foot {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 40px;
    background-color: #fff;
  }
}
</style>
