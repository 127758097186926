<template>
  <div class="">
    <el-tooltip popper-class="tooltip" effect="light" :content="isLoading?'正在翻译请勿关闭':'全文翻译'"
                placement="bottom" :visible-arrow="false" :enterable="false">
      <div class="translate_all hand flc" @click="translatePdfClick" ref="translate">
        <span v-show="(pdfTranslateInfo.traCount || pdfZhInfo.id) || isLoading"
              id="translateIcon"></span>
        <i v-show="!(pdfTranslateInfo.traCount || pdfZhInfo.id) && !isLoading"
           class="iconfont fs20 icon-translate"></i>
        <span class="fs12"
              v-if="!isLoading">{{pdfZhInfo.id || pdfTranslateInfo.traCount?'查看译文':'全文翻译'}}</span>
        <span class="fs12" v-if="isLoading">翻译中，请勿关闭当前页面</span>
      </div>
    </el-tooltip>
    <DownloadApp :show.sync="isShow" />
    <MyDialog ref="translateDialog" :isShow.sync="showTransWarn" title="规则说明" :position="options"
              width="550px" height="240px">
      <div class="rule_content">
        <p v-for="(warn,i) in ruleList" :key="i" v-html="warn.text"></p>
      </div>
      <!-- 免费次数翻译 -->
      <div class="options" v-if="(3 - (pdfTranslateInfo.traCountToday || 0)) > 0">
        <span class="residue_num">当前剩余次数：
          <i>{{ 3 - (pdfTranslateInfo.traCountToday || 0)}}</i>
          次</span>
        <span class="start_trans_btn hand"
              :class="{'disable_btn':pdfTranslateInfo.traCountToday === 3}"
              @click="startTranslate">开始翻译</span>
      </div>
      <!-- 积分兑换 -->
      <div v-else class="options integral_option">
        <div class="integral_tip">
          <p>今日已翻译了3篇，请明天再来或通过消耗 66 积分翻译原文~</p>
          <p class="secondary mt5">当前账户剩余积分：<span>{{$store.getters.userInfo.signScore}}</span></p>
        </div>
        <span v-if="$store.getters.userInfo.signScore - 66 >=0" class="integralExchange_btn hand"
              @click="integralExchange">
          <span class="btn_text">积分兑换</span>
        </span>
        <span v-else @click="openDownloadApp" class="integralExchange_btn hand earn_btn">
          <span class="btn_text earn_btn_text">赚取积分</span>
        </span>
      </div>
    </MyDialog>

    <!-- <MyDialog ref="pointsTipDialog" :isShow.sync="isShowPointsTipDialog" title="温馨提示" width="550px"
              height="230px">

    </MyDialog> -->
    <DownloadApp :show.sync="isShowPointsTipDialog">
      <template #title>
        <div class="pointsTipDialogTitle">
          前往<span class="title_text">[APP-我的-积分中心]</span>
          赚取积分
        </div>
      </template>
      <template #foot>
        <div>
          <p class="fs16">扫码下载 Info X Med APP</p>
        </div>
      </template>
    </DownloadApp>
  </div>
</template>

<script>
import MyDialog from '@c/myDialog/newIndex.vue'
import { getPdfZhStatusPro, getUserTranslatePdfInfo, getTranslatePdfInfo } from '@/api/article.js'
import lottie from 'lottie-web'
import axios from 'axios'
import DownloadApp from '@c/downloadAppDlalog/index.vue'

export default {
  props: {
    pdfPageTotal: {
      type: Number,
      default: 0
    },
    documentId: {
      type: String,
      default: ''
    },
    pdfZhInfo: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    DownloadApp,
    MyDialog,
    DownloadApp
  },
  data () {
    return {
      ruleList: [
        {
          text: '1、原文翻译需要等待，<span class="warn_highlight">翻译过程中请勿关闭当前页</span>'
        }, {
          text: '2、全文翻译功能目前属于内测阶段，每天支持翻译3次。'
        }
        , {
          text: '3、全文翻译次数使用完以后，支持消耗积分翻译全文。'
        }
      ],
      isShow: false,
      options: {},
      showTransWarn: false,
      ws: {},
      isLoading: false,
      waitTimeId: null,
      inquiryNums: 0,
      anim: {},
      pdfTranslateInfo: {},
      isShowPointsTipDialog: false
    }
  },
  methods: {
    openDownloadApp () {
      this.isShowPointsTipDialog = true
      this.$refs.translateDialog.closeDialog()
    },
    integralExchange () {
      this.$refs.translateDialog.closeDialog()
      this.translatePdfServe(true, () => {
        this.$message.success('积分兑换成功，正在翻译请稍后')
      })
    },
    async getUserTranslatePdfInfo () {
      const { data } = await getUserTranslatePdfInfo({
        documentId: this.documentId
      })
      this.pdfTranslateInfo = data
    },
    async initLottie () {
      const { data } = await axios.get('https://img.infox-med.com/webImg/public/animIcons/translateIcon/data.json')
      this.anim = lottie.loadAnimation({
        container: document.querySelector('#translateIcon'),
        renderer: 'svg',
        loop: true,
        autoplay: false,
        name: 'translateIcon',
        animationData: data
      })
    },
    showPdfZh (data) {
      this.$emit('showPdfZh', data)
    },
    clearTime () {
      if (this.waitTimeId) {
        this.inquiryNums = 0
        this.isLoading = false
        clearInterval(this.waitTimeId)
      }
    },
    async getPdfTranslateStatus () {
      if (this.inquiryNums >= 15) {
        this.clearTime()
        this.$notify({
          title: '系统提示',
          message: '服务器繁忙，请稍后重试',
          duration: 0
        })
        return
      }

      const { data } = await getPdfZhStatusPro({
        documentId: this.documentId
      })
      if (data === 2) {
        this.clearTime()
        this.translatePdfServe()
      } else {
        this.waitTimeId = setInterval(() => {
          this.inquiryNums++
          clearInterval(this.waitTimeId)
          this.getPdfTranslateStatus()
        }, 1000 * 3)
      }
    },
    async translatePdfServe (type = false, fn) {
      try {
        this.isLoading = true
        // const res = await getPdfZh({
        //   documentId: this.documentId
        // })
        let body = { documentId: this.documentId }
        if (type) {
          body.useSignScore = 1
        }
        const res = await getTranslatePdfInfo(body)
        fn && fn()
        if (res.code === 0) {
          this.isLoading = false
          this.showPdfZh(res.data)
        } else {
          this.getPdfTranslateStatus()
        }
      } catch (error) {
        this.isLoading = false
        if (error === '积分不足') {
          this.isShowPointsTipDialog = true
          this.$refs.translateDialog.closeDialog()
        } else {
          this.$message.error(error)
        }
      }
    },
    beforeunload (event) {
      var confirmationMessage = 'PDF正在翻译中, 确定要离开此页面吗？';
      (event || window.event).returnValue = confirmationMessage
      return confirmationMessage
    },
    async getTranslatePdfInfo () {
      const res = await getTranslatePdfInfo({
        documentId: this.documentId
      })
      this.showPdfZh(res.data)
    },
    startTranslate () {
      if (this.pdfTranslateInfo.traCountToday === 3) return
      this.$refs.translateDialog.closeDialog()
      this.translatePdfServe()
    },
    translatePdfClick () {
      // this.showPdfZh(this.pdfZhInfo)
      // return
      if (!this.$store.getters.userInfo.isHy) return (this.isShow = true)
      if (this.isLoading) return
      if (this.pdfZhInfo.id) {
        return this.showPdfZh(this.pdfZhInfo)
      } else if (this.pdfTranslateInfo.traCount) {
        return this.translatePdfServe()
      } else {
        this.showTransWarn = true
      }
    }
  },
  mounted () {
    const box = this.$refs?.translate?.getBoundingClientRect()
    this.options = {
      x: box.x + 20,
      y: box.y + 10
    }
    this.initLottie()
  },
  watch: {
    isLoading (newVal) {
      if (newVal) {
        window.addEventListener('beforeunload', this.beforeunload)
        this.anim.play && this.anim.play()
      } else {
        window.removeEventListener('beforeunload', this.beforeunload)
        this.anim.stop && this.anim.stop()
      }
    }
  },
  created () {
    this.getUserTranslatePdfInfo()
  }
}
</script>

<style lang="less" scoped>
.translate_all {
  padding: 0 5px;
  height: 28px;
  text-align: center;
  line-height: 28px;
  border-radius: 3px;
  font-size: 18px;
  margin-right: 3px;
  color: #000;
  background-color: #f9f9fa;
  &:hover {
    background-color: #dddedf;
  }
}
.rule_content {
  padding: 20px;
  font-size: 15px;
  line-height: 2em;

  /deep/.warn_highlight {
    color: #4b639f;
  }
}
.options {
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  .residue_num {
    font-size: 14px;
    i {
      font-weight: 600;
      color: #4b639f;
    }
  }
  .start_trans_btn {
    padding: 2px 10px;
    font-size: 15px;
    border: 1px solid #4b639f;
    color: #4b639f;
    border-radius: 4px;
    &:hover {
      color: #fff;
      background-color: #4b639f;
    }
  }
  .disable_btn:hover {
    color: #4b639f;
    background-color: transparent;
  }
}

.integral_tip {
  font-size: 15px;
  background: linear-gradient(to top right, #fe9317, #ff5027);
  -webkit-background-clip: text;
  color: transparent;
  font-weight: 600;
}
.secondary {
  font-size: 12px;
  // color: #666;
}

.integralExchange_btn {
  position: relative;
  font-size: 15px;
  width: 82px;
  height: 27px;
  // border: 1px solid #4b639f;
  // color: #4b639f;
  border-radius: 4px;
  background: linear-gradient(to top right, #fe9317, #ff5027);
  &::after {
    content: '';
    position: absolute;
    left: 1px;
    top: 1px;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    background-color: #fff;
    border-radius: 4px;
  }
  &:hover {
    &::after {
      background: linear-gradient(to top right, #fe9317, #ff5027);
    }
    .btn_text {
      color: #fff;
      font-weight: 600;
    }
  }
  .btn_text {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    text-align: center;
    transform: translate(-50%, -50%);
    background: linear-gradient(to top right, #fe9317, #ff5027);
    background-clip: text;
    color: transparent;
    z-index: 99;
  }
}
.earn_btn {
  background: linear-gradient(to top right, #a4d7ff, #206bed);
  &:hover {
    &::after {
      background: linear-gradient(to top right, #a4d7ff, #206bed);
    }
  }
  .earn_btn_text {
    background: linear-gradient(to top right, #a4d7ff, #206bed);
    background-clip: text;
    color: transparent;
  }
}

.translate_icon_box {
  position: absolute;
  top: 0;
  right: 200px;
}
#translateIcon {
  width: 20px;
  height: 20px;
  margin-right: 3px;
}
.pointsTipDialogTitle {
  padding-top: 20px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2px;
  .title_text {
    background: linear-gradient(to top right, #a4d7ff, #206bed);
    background-clip: text;
    color: transparent;
  }
}
</style>
