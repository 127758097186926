<template>
  <MyDialog ref="dialog" :isShow="show" @handleClose="handleClose" width="400px" height="380px"
            :isTitle="false">
    <div class="no-vip">
      <i class="el-icon-close hand close_icon" @click="iconClose"></i>
      <img class="title-img" src="@/assets/img/pdf/vip.png">
      <div>下载APP开通会员，享受专属特权</div>
      <img class="ma" src="@/assets/img/index/dowAPP.png">
    </div>
  </MyDialog>
</template>

<script>
import MyDialog from '@c/myDialog/newIndex.vue'
export default {
  props: {
    show: {
      type: Boolean,
      default: true
    }
  },
  components: { MyDialog },
  data () {
    return {

    }
  },
  methods: {
    iconClose () {
      this.$refs.dialog.closeDialog()
    },
    handleClose () {
      this.$emit('update:show', false)
    }
  },
  created () {

  }
}
</script>

<style lang="less" scoped>
/deep/.dialog_boby {
  background-color: transparent;
}

.no-vip {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 380px;
  height: 375px;
  overflow: hidden;
  border-radius: 15px;
  .title-img {
    width: 100%;
  }
  div {
    width: 100%;
    height: 100%;
    padding: 25px 0 0;
    line-height: 40px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    background-color: #fff;
  }
  i {
    position: absolute;
    right: 6px;
    top: 78px;
    font-size: 28px;
    color: #fff;
    transform: rotate(0deg);
    transition: all 0.3s;
    &:hover {
      transform: rotate(180deg);
    }
  }
  .ma {
    position: absolute;
    left: 137px;
    bottom: 22px;
  }
}
</style>
